import React, { forwardRef, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import { GetReferrer } from "@website-builder/utilities/utils/GetReferrer.js";
import { validateEmail } from "@website-builder/utilities/utils/validateEmail.js";
import {
	FAILED_EVENTS,
	BUTTON_INTERACTION,
} from "@website-builder/utilities/constants/click-events.js";
import {
	addToDataLayer,
	callB2CIdentifyEvent,
} from "@website-builder/utilities/utils/utils.js";
import { MOBILE_L_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import PropTypes from "prop-types";
import { Text, Button, Icon } from "@website-builder/ui/shared/elements";

import { InputWithButtonWrapper, ErrorSection } from "./styles";

const InputWithButton = forwardRef(
	(
		{
			inputType,
			inputId = null,
			placeholder,
			buttonText,
			disabled,
			buttonEvent,
			agreementCheck,
			apiEndPoint,
			formData,
			onSuccess = () => {},
			redirectUrl,
			context = {},
			workshopId = "",
			pageSection = "",
			...restProps
		},
		ref,
	) => {
		const [focused, setFocused] = useState(false);
		const [input, setInput] = useState("");
		const [inputError, setInputError] = useState("");
		const isMobile = useMediaQuery(`(max-width: ${MOBILE_L_BP}px)`);
		const [loading, setLoading] = useState(false);

		const callbackOnError = () => {
			setLoading(false);
		};
		const handleClick = () => {
			const [referral, referral2] = GetReferrer();
			var agreementStatus = true;
			var eventData = {
				page_section: pageSection,
				course_slug: context?.slug,
				email: input,
				referral,
				referral2,
				click_text: buttonText,
				products: JSON.stringify([{ product_id: workshopId }]),
			};

			if (agreementCheck) {
				agreementStatus = agreementCheck?.();
				eventData["has_user_agreed"] = agreementStatus;
			}

			if (inputType === "email") {
				let result = validateEmail(input);
				if (result !== true) {
					setInputError(result);
					addToDataLayer({
						event: FAILED_EVENTS[buttonEvent],
						...eventData,
					});
					return;
				} else if (!agreementStatus) {
					addToDataLayer({
						event: FAILED_EVENTS[buttonEvent],
						...eventData,
					});
					setInputError("");
					return;
				} else {
					setInputError("");
				}
			}
			const cta = {
				event: buttonEvent,
				link: {},
			};
			const apiData = {
				context,
				params: {
					email: input,
				},
				onSuccess,
			};
			try {
				setLoading(true);
				handleCTAClick(cta, eventData, apiData, {}, { callbackOnError });
				eventData?.email && callB2CIdentifyEvent(eventData?.email);
			} catch (error) {
				addToDataLayer({
					event: FAILED_EVENTS[BUTTON_INTERACTION.SYLLABUS_DOWNLOAD],
					...eventData,
				});
				console.error(error);
				setLoading(false);
			}
		};
		return (
			<div className="input-with-button">
				<InputWithButtonWrapper
					{...restProps}
					error={!focused && inputError ? true : false}
				>
					<input
						type={inputType}
						id={inputId}
						placeholder={placeholder}
						required
						value={input}
						onChange={(event) => setInput(event?.target?.value)}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						ref={ref}
					/>
					{isMobile ? (
						<></>
					) : (
						<Button
							buttonText={buttonText}
							type="button"
							onClick={handleClick}
							disabled={loading || disabled}
						/>
					)}
				</InputWithButtonWrapper>
				{isMobile ? (
					<Button
						style={{ marginTop: "0.5rem" }}
						buttonText={buttonText}
						type="button"
						onClick={handleClick}
						disabled={loading || disabled}
					/>
				) : (
					<></>
				)}
				{!focused && inputError ? (
					<ErrorSection>
						<Icon iconName="warning" />
						<Text variant="paragraph_S" className="errorMessage">
							{inputError}
						</Text>
					</ErrorSection>
				) : null}
			</div>
		);
	},
);

InputWithButton.propTypes = {
	inputType: PropTypes.string,
	placeholder: PropTypes.string,
	buttonText: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};

InputWithButton.defaultProps = {
	inputType: "email",
	placeholder: "",
	buttonText: "",
	onClick: () => {},
	disabled: false,
};

export default InputWithButton;
