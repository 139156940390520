import styled from "styled-components";

export const InputWithButtonWrapper = styled.div`
	background: var(--primary-neutral-white);
	width: 100%;
	padding: 0.5rem;
	border: 1px solid
		${(props) =>
			props.error
				? "var(--secondary-red-200)"
				: "var(--secondary-gray-medium)"};
	border-radius: 4px;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	& > input {
		border: none;
		flex: auto;

		font-family: var(--desktop-body-m-regular-ff);
		font-weight: var(--desktop-body-m-regular-fw);
		line-height: var(--desktop-body-m-regular-lh);
		font-size: calc(var(--desktop-body-m-regular-fs) * 1px);
		letter-spacing: var(--desktop-body-m-regular-ls);
		text-transform: var(--desktop-body-m-regular-tc);
		text-decoration: var(--desktop-body-m-regular-td);
		color: var(--primary-neutral-nightshade-800);

		:focus {
			outline: none;
		}
	}
`;
export const ErrorSection = styled.div`
	display: flex;
	align-items: center;
	gap: 0.25rem;
	margin-top: 8px;
	.errorMessage {
		color: var(--secondary-red-300);
		margin: 0;
	}

	& > figure > svg > path:not(:last-child) {
		stroke: var(--secondary-red-200);
	}
	& > figure > svg > path:last-child {
		fill: var(--secondary-red-200);
	}
`;
